import { CircularProgress, Backdrop } from '@mui/material';

const PageLoader = ({ isRequesting }) => {
  return (
    <Backdrop
      open={isRequesting}
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <CircularProgress color="inherit" size={60} />
    </Backdrop>
  );
};

export default PageLoader;
