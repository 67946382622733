import { Box, Button, CircularProgress, Input, IconButton } from '@mui/material';
import { PhotoCamera, Close } from '@mui/icons-material';
import imageCompression from 'browser-image-compression';
import { useEffect, useState } from 'react';

import { convertToBase64, isValidBase64Image } from '../../helpers';

import styles from './fields.module.css';

const UploadFileField = ({ field, formData, setFormData, setErrors, refs, errors }) => {
  const [previewImg, setPreviewImg] = useState(null);
  const [loading, setLoading] = useState(false);
  const fieldValue = formData[field.name];

  const handleFileChange = async (e) => {
    const { name, files } = e.target;
    const file = files[0] || null;
    if (!file) return;
    setLoading(true);

    try {
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 0.7,
        useWebWorker: true,
        initialQuality: 0.7,
        fileType: 'image/jpeg',
        maxWidthOrHeight: 1920,
        alwaysKeepResolution: false,
      });
      const base64 = await convertToBase64(compressedFile);
      if (!isValidBase64Image(base64)) {
        throw new Error('Invalid Image Format');
      }

      setPreviewImg(base64);
      setFormData((prevState) => ({
        ...prevState,
        [name]: base64,
      }));
      setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    } catch (error) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: true }));
    } finally {
      setLoading(false);
    }
  };

  const handleClear = (e) => {
    e.preventDefault();
    setPreviewImg(null);
    setFormData((prevState) => ({
      ...prevState,
      [field.name]: null,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [field.name]: false }));
  };

  useEffect(() => {
    if (fieldValue) {
      if (typeof fieldValue === 'string' && fieldValue.startsWith('data:image/')) {
        setPreviewImg(fieldValue);
        setLoading(false);
      } else {
        setLoading(true);
      }
    } else {
      setLoading(false);
    }
  }, [fieldValue]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      marginBottom={field.marginBottom}
    >
      <label htmlFor={field.name} className={styles.previewContainer}>
        <>
          {previewImg ? (
            <img src={previewImg} alt="Preview" className={styles.previewImage} />
          ) : loading ? (
            <CircularProgress color="inherit" size={40} />
          ) : (
            <PhotoCamera className={styles.photoIcon} />
          )}
          <IconButton className={styles.clearButton} onClick={handleClear} aria-label="Clear" title="Clear">
            <Close />
          </IconButton>
        </>
      </label>
      <Input
        fullWidth
        type="file"
        sx={field.sx}
        id={field.name}
        key={field.name}
        name={field.name}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        ref={refs.current[field.name]}
        inputProps={{ accept: 'image/jpeg,image/png,image/heic,image/heif,image/gif,image/webp,image/bmp,image/tiff' }}
      />
      <div>
        <label htmlFor={field.name} className={styles.buttonLabel}>
          <Button variant="outlined" color="primary" component="span" fullWidth>
            {field.label}
            {field.required && <span className={styles.requiredAsterisk}>&nbsp;*</span>}
          </Button>
        </label>
      </div>
      {!!errors[field.name] && <div className={styles.helperText}>{field.helperText}</div>}
    </Box>
  );
};

export default UploadFileField;
