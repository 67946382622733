import { Autocomplete, TextField, MenuItem, CircularProgress } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import _debounce from 'lodash/debounce';

import { newPatientOption } from '../helpers';

const PatientSearch = ({ preSelectedPatient, onChange }) => {
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [patients, setPatients] = useState([]);
  const [cache, setCache] = useState({});

  const token = localStorage.getItem('authToken');
  const clientId = localStorage.getItem('clientId');

  const fetchPatients = useCallback(
    _debounce(async (searchQuery) => {
      if (!searchQuery) return;
      setLoading(true);

      if (cache[searchQuery] && cache[searchQuery].length) {
        setPatients(cache[searchQuery]);
        setLoading(false);
        return;
      }

      const res = await fetch(`/api/patients?name=${encodeURIComponent(searchQuery)}&clientId=${clientId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (res.ok) {
        const data = await res.json();
        if (data.patients && data.patients.length) {
          setPatients(data.patients);
          setCache((prevCache) => ({ ...prevCache, [searchQuery]: data.patients }));
        }
      }

      setLoading(false);
    }, 500),
    [cache, token],
  );

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handlePatientSelect = (event, newValue) => {
    setSelectedPatient(newValue);
    onChange(newValue);
    setInputValue('');
  };

  useEffect(() => {
    if (inputValue.length >= 1) {
      fetchPatients(inputValue);
    } else {
      setPatients([]);
    }
  }, [inputValue, fetchPatients]);

  useEffect(() => {
    if (preSelectedPatient) {
      const patient = preSelectedPatient.Id !== 'NEW' ? preSelectedPatient : newPatientOption;
      setSelectedPatient(patient);
    } else {
      setSelectedPatient(null);
      setInputValue('');
      setPatients([]);
    }
  }, [preSelectedPatient]);

  return (
    <Autocomplete
      options={patients}
      value={selectedPatient}
      inputValue={inputValue}
      onChange={handlePatientSelect}
      onInputChange={handleInputChange}
      noOptionsText={inputValue.length >= 1 ? "No patients found" : "Search..."}
      getOptionLabel={(patient) => (patient.Id === 'NEW' ? patient.Label : `${patient.FirstName} ${patient.LastName}`)}
      isOptionEqualToValue={(option, value) => option.Id === value?.Id}
      fullWidth
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search for a patient by last name"
          placeholder="Enter patient's last name"
          fullWidth
          sx={{ mb: 4 }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <MenuItem {...props} key={option.Id} value={option.Id}>
          {option.Id === 'NEW' ? option.Label : `${option.FirstName} ${option.LastName}`}
        </MenuItem>
      )}
    />
  );
};

export default PatientSearch;
