import { Button, Grid2 as Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useState } from 'react';

import ProfileMenu from '../profile-menu';
import IntakeModal from '../intake-modal';

import styles from './index.module.css';

const Header = ({ title, setToastOpen, setToastMessage }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleLogout = () => {
    navigate('/admin/logout');
  };

  const handleDashboard = () => {
    navigate('/admin/dashboard');
  };

  const handleApi = () => {
    navigate('/admin/dashboard/api-manager');
  };

  const handleProfile = () => {
    navigate('/admin/dashboard/profile-settings');
  };

  return (
    <>
      <header className={styles.header}>
        <Grid container spacing={0} alignItems="center" justifyContent="space-between">
          <Grid className={styles.gridItem}>
            <Link to="/admin/dashboard">
              <img src="/health-of-your-space.png" title="Health of Your Space" alt="Health of Your Space" />
            </Link>
          </Grid>
          <Grid className={styles.gridItem}>
            <h1>{title}</h1>
          </Grid>
          <Grid container flexDirection="row" justifyContent="right" alignItems="center" className={styles.gridItem}>
            <Button variant="contained" onClick={() => setOpen(true)} className={styles.intakeButton}>
              Intake
            </Button>
            <ProfileMenu handleDashboard={handleDashboard} handleProfile={handleProfile} handleApi={handleApi} handleLogout={handleLogout} />
          </Grid>
        </Grid>
      </header>
      <IntakeModal open={open} onClose={() => setOpen(false)} setToastOpen={setToastOpen} setToastMessage={setToastMessage} />
    </>
  );
};

export default Header;
