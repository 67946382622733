import { CircularProgress, Box } from '@mui/material';

const Loader = ({ sx }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" width="100%" sx={sx}>
      <CircularProgress sx={{ color: '#254f38' }} size={40} />
    </Box>
  );
};

export default Loader;
