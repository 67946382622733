import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import { useRef } from 'react';

import styles from './fields.module.css';

const CheckboxField = ({ field, formData, setFormData, setErrors, refs, errors }) => {
  const checkboxRefs = useRef({});

  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData((prevState) => {
      let convertedValues = prevState[name];
      if (typeof convertedValues === 'string') {
        convertedValues = convertedValues.split(',');
      }

      const selectedValues = convertedValues || [];
      if (value === 'None' && checked) {
        return {
          ...prevState,
          [name]: ['None'],
        };
      } else if (checked) {
        const updatedValues = selectedValues.includes('None') ? selectedValues.filter((val) => val !== 'None') : selectedValues;
        return {
          ...prevState,
          [name]: [...updatedValues, value].filter((val) => !!val),
        };
      } else {
        return {
          ...prevState,
          [name]: selectedValues.filter((val) => val !== value),
        };
      }
    });

    setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
  };

  const focusCheckboxGroup = () => {
    const firstCheckbox = Object.values(checkboxRefs.current)[0];
    if (firstCheckbox) {
      firstCheckbox.focus();
    }
  };

  refs.current[field.name] = { focus: focusCheckboxGroup };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" width="100%" marginBottom={field.marginBottom}>
      <FormControl fullWidth error={!!errors[field.name]} sx={{ maxWidth: 600, textAlign: 'left' }}>
        {field.label && (
          <FormLabel
            id="checkbox-group-label"
            sx={{ marginBottom: '10px', color: 'black', fontWeight: field.boldLabel ? 'bold' : 'normal' }}
          >
            {field.label}
            {(field.required || field.allRequired) && <span style={{ color: 'red', whiteSpace: 'nowrap' }}>&nbsp;*</span>}
          </FormLabel>
        )}
        <FormGroup
          sx={{
            display: 'grid',
            gap: 1,
          }}
          className={field.oneColumn ? styles.formGroupOneColumn : styles.formGroup}
        >
          {field.options.map((o) => (
            <FormControlLabel
              key={o.value}
              sx={{
                '& .MuiFormControlLabel-label': { fontSize: '14px' },
              }}
              control={
                <Checkbox
                  value={o.value}
                  name={field.name}
                  onChange={handleCheckboxChange}
                  checked={formData[field.name]?.includes(o.value) || false}
                  inputRef={(el) => {
                    if (el) {
                      checkboxRefs.current[o.value] = el;
                    } else {
                      delete checkboxRefs.current[o.value];
                    }
                  }}
                />
              }
              label={
                <>
                  {field.allRequired && <span style={{ color: 'red', whiteSpace: 'nowrap' }}>*&nbsp;</span>}
                  {o.label}
                </>
              }
            />
          ))}
        </FormGroup>
      </FormControl>
    </Box>
  );
};

export default CheckboxField;
