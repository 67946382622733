import { useEffect } from 'react';

function useDebounce(callback, delay, dependencies) {
  useEffect(() => {
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => clearTimeout(handler);
  }, [callback, delay, ...dependencies]);
}

export default useDebounce;
