import { CheckCircle } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

import styles from './checkmark.module.css';

const CheckmarkAnimation = ({ message }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <CheckCircle className={styles.checkmarkAnimation} style={{ fontSize: '100px', color: 'green' }} />
      <Typography variant="h5" className={styles.fadeInText}>
        {message}
      </Typography>
    </Box>
  );
};

export default CheckmarkAnimation;
