import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Divider } from '@mui/material';
import { Close } from '@mui/icons-material';

import styles from './confirm-dialog.module.css';

const ConfirmDialog = ({ open, onClose, closeLabel, hideCloseBtn, onConfirm, confirmLabel, title, message, description }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-title"
      aria-describedby="confirm-message"
    >
      <DialogTitle id="confirm-title" className={styles.modalTitle}>
        {title} <Close onClick={onClose} sx={{ cursor: 'pointer' }} />
      </DialogTitle>
      <DialogContent>
        {message && (
          <DialogContentText id="confirm-message" sx={description && { marginBottom: '20px' }}>
            {message}
          </DialogContentText>
        )}
        {description && (
          <DialogContentText id="confirm-description" className={styles.description}>
            {description}
          </DialogContentText>
        )}
      </DialogContent>
      <Divider />
      <DialogActions sx={{ padding: '20px' }}>
        {onClose && !hideCloseBtn && (
          <Button onClick={onClose} color="primary">
            {closeLabel || 'Cancel'}
          </Button>
        )}
        {onConfirm && (
          <Button onClick={onConfirm} color="primary" variant="contained">
            {confirmLabel || 'Confirm'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
