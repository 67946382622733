import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';

import ProfileSettings from './pages/admin/dashboard/profile-settings';
import ApiManager from './pages/admin/dashboard/api-manager';
import ProtectedRoute from './components/protected-route';
import Dashboard from './pages/admin/dashboard';
import Logout from './pages/admin/logout';
import NotFound from './pages/not-found';
import Login from './pages/admin/login';
import Intake from './pages/intake';
import theme from './theme';

import './index.css';

const titleSuffix = 'HoYS Platform';
const titleMap = {
  '/admin/dashboard/profile-settings': `Profile Settings - ${titleSuffix}`,
  '/admin/dashboard/api-manager': `API Manager - ${titleSuffix}`,
  '/admin/dashboard': `Dashboard - ${titleSuffix}`,
  '/admin/login': `Login - ${titleSuffix}`,
};

const HoysPlatformClient = () => {
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname;
    let dynamicTitle = titleMap[pathname] || `Page Not Found - ${titleSuffix}`;
    if (pathname.includes('/clients/')) {
      dynamicTitle = 'Patient Intake Form';
    }
    document.title = dynamicTitle;
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route
          path="/admin/dashboard"
          element={
            <ProtectedRoute roles={['client']} key="admin-dashboard">
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/dashboard/profile-settings"
          element={
            <ProtectedRoute roles={['client']} key="admin-dashboard-profile-settings">
              <ProfileSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/dashboard/api-manager"
          element={
            <ProtectedRoute roles={['admin']} key="admin-dashboard-api-manager">
              <ApiManager />
            </ProtectedRoute>
          }
        />
        <Route
          path="/clients/:clientId"
          element={
            <ProtectedRoute roles={['user']} otp={true} key="user-intake">
              <Intake />
            </ProtectedRoute>
          }
        />
        <Route path="/admin" element={<Navigate to="/admin/dashboard" />} />
        <Route path="/" element={<Navigate to="/admin/dashboard" />} />
        <Route path="/admin/logout" element={<Logout />} />
        <Route path="/admin/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ThemeProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <HoysPlatformClient />
    </Router>
  </React.StrictMode>,
);
