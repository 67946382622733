import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'var(--font-roboto)',
  },
  palette: {
    primary: {
      main: '#254f38',
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: 'rgb(211, 47, 47)' },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        asterisk: { color: 'rgb(211, 47, 47)' },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          cursor: 'pointer !important',
        },
        label: {
          fontSize: '14px',
          cursor: 'pointer',
          '@media (max-width:430px)': {
            fontSize: '10px',
          },
          '@media (max-width:390px)': {
            fontSize: '8px',
          },
        },
      },
    },
  },
});

export default theme;
