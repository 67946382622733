import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Box, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

import { getDynamicRequired } from '../../helpers';

const filter = createFilterOptions();

const SelectTextField = ({ field, formData, setFormData, setErrors, refs, errors, dynamicState }) => {
  const options = (field.optionsByDynamicStateKey ? dynamicState[field.optionsByDynamicStateKey] : field.options) || [];

  const foundOption = options.find((o) => formData[field.name] === o.label);
  const isFieldSet = formData[field.name] && formData[field.name].trim().length >= 2;
  const [value, setValue] = useState(foundOption || (isFieldSet && { label: formData[field.name] }) || null);

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      [field.name]: value?.label || '',
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [field.name]: false }));
  }, [setFormData, setErrors, field.name, value]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" width="100%" marginBottom={field.marginBottom}>
      <Autocomplete
        freeSolo
        fullWidth
        clearOnBlur
        selectOnFocus
        value={value}
        id={field.name}
        handleHomeEndKeys
        options={options}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setValue({
              label: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            setValue({
              label: newValue.inputValue,
            });
          } else {
            setValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          const isExisting = options.some((option) => inputValue === option.label);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              label: `Add "${inputValue}"`,
            });
          }
          return filtered;
        }}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          } else if (option.inputValue) {
            return option.inputValue;
          } else {
            return option.label;
          }
        }}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              {option.label}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            name={field.name}
            label={field.label}
            error={!!errors[field.name]}
            inputRef={refs.current[field.name]}
            helperText={!!errors[field.name] && field.helperText}
            required={getDynamicRequired(formData, field.required)}
          />
        )}
      />
    </Box>
  );
};

export default SelectTextField;
